.servicios__section {
  align-items: center;
  background-color: #fff;
  display: grid;
  justify-content: center;
  min-height: 100vh;
  padding: 5rem 9%;
  text-align: center;
  width: 100%;
}

.servicios__card-container {
  padding: 0.5rem;
}

.servicios__scrollContainer {
  height: 100%;
}

.servicios__card {
  // background-color: lighten($color: $background-primary, $amount: 5);
  // border: 1px solid $light-gray;
  // border-radius: 3px;
  // box-shadow: 3px 5px 11px 0 rgba(202, 202, 202, 0.549);
  align-content: flex-start;
  cursor: default;
  display: grid;
  height: 100%;
  min-height: 300px;
  padding: 2rem;
  // position: relative;
  text-align: center;
  transition: transform 300ms ease-in-out;

  h3 {
    margin: 2rem 0;
  }

  // &:hover {
  //     transform: scale(1.03);
  // }
}

.servicios__card-full {
  display: grid;
  height: 100%;
}

@media (max-width: 400px) {
  .servicios__card {
    padding: 1rem;
  }
}

.servicios__icon {
  color: #253450;
  font-size: 2.5rem;
}

.servicios__content-bold {
  align-self: flex-end;
  font-weight: 900;
  margin-top: 2rem;
}

.servicios__full-btn {
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

.servicios__small-btn {
  margin: auto;
}
