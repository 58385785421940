.about__section {
  align-items: center;
  display: grid;
  min-height: 100vh;
  padding: 5rem 9%;
  text-align: center;

  h2,
  p {
    margin: auto;
    max-width: 700px;
  }

  h2 {
    margin-bottom: 3rem;
  }
}
