.navbar {
    padding: 0.3rem 2rem;
    position: fixed;
    top: 0;
    transition: all 400ms ease-out;
    width: 100vw;
    z-index: 2;
}

.nav-top {
    padding: 3rem 3rem 3rem 1rem;
}

.nav-full {
    background-color: $dark-gray;
    padding: 0.5rem 3rem 0.5rem 3rem;
}

.navbar__logo {
    transition: 400ms ease-in-out all;
}


.navbar__logo-top {
    width: 350px;
}

.navbar__logo-full {
    width: 250px;
}

@media (max-width: 767px) {
    .nav-top, .nav-full {
        background-color: $dark-gray;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    }

    .navbar__logo-top, .navbar__logo-full {
        width: 230px;
    }
}

@media (max-width: 360px) {
    .navbar__logo-top, .navbar__logo-full {
        width: 160px;
    }
}

.nav-link {
    color: rgba(255, 255, 255, 0.59);
    font-weight: 500;

    transition: 200ms ease all;

    &:hover {
        color: #fff;
    }
}

.navbar-toggler {
    color: red;
}


@media (max-height: 460px) {
    .navbar {
        display: none;
    }
    
}
