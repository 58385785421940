.blog__section {
  background-color: $background-primary;
  margin: 0;
  min-height: 100vh;
  padding: 5rem 9%;
  text-align: center;
  width: 100%;
}

.blog__post-container {
  padding: 0rem;
}

.blog__btn {
  align-self: flex-end;
  border: 2px solid $primary;
  color: $primary;
  font-weight: 700;
  margin: 2rem auto 1rem;

  &:hover {
    background-color: $primary;
    color: #fff;
  }
}

.blog__post-card {
  background-color: #fff;
  display: grid;
  height: 550px;
  padding: 1.5rem 1rem;

  h3 {
    margin-bottom: 1.4rem;
  }

  .blog__btn {
    align-self: flex-end;
    border: 2px solid $primary;
    color: $primary;
    font-weight: 700;
    margin: 2rem auto 1rem;

    &:hover {
      background-color: $primary;
      color: #fff;
    }
  }
}

.blog__content,
.blog__content-middle {
  height: 100%;
  overflow: hidden;
  text-overflow: clip;
}

// .blog__content::after, .blog__content-middle::after {
//     content:'';
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     left: 0;
//     top: -110px;
// }

// .blog__content::after {
//     background: linear-gradient(transparent 220px, #fff);
// }

// .blog__content-middle::after {
//     background: linear-gradient(transparent 220px, $primary);
// }

.blog__post-date {
  color: lighten($color: $dark-gray, $amount: 40);
  font-weight: 500;
  justify-self: flex-start;
}

.blog__middle-card {
  background-color: $primary;
  color: #fff;

  p {
    color: #fff;
  }

  .blog__btn {
    align-self: flex-end;
    border: 2px solid #fff;
    color: #fff;
    font-weight: 700;
    margin: 2rem auto 1rem;

    &:hover {
      background-color: #fff;
      color: $primary;
    }
  }
}

.separator a img {
  width: 90%;
}
