.contact__section {
    background-color: $dark-gray;
    color: #fff;
    font-weight: 200;
    padding: 7rem 9% 5rem;
    position: relative;
    text-align: center;

    h2 {
        color: #fff;
    }

}

.contact__container {
    margin-bottom: 2rem;
}

.contact__text {
    word-break: break-all;
    font-size: 0.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
}

.contact__icon {
    height: 32px;
    margin-bottom: 1rem;
    width: 32px;
}

.contact__logo {
    left: 0;
    position: absolute;
    top: 0;
    width: 100px;
}

@media (max-width: 767px) {
    .contact__logo {
        left: 0;
        right: 0;
        margin: auto;
    }
}

.fa-twitter {
    color: $twitter;
}

.fa-whatsapp {
    background-color: $wpp;
    border-radius: 50%;
}

.fa-instagram {
    color: $instagram2;
}

.fa-linkedin {
    border-radius: 5%;
    color: #fff;
    height: 28px;
    width: 28px;
}

.contact__ciudad {
    font-weight: 300;
    margin-bottom: 4rem;
}