@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Major+Mono+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
    box-sizing: border-box;
    font-family: 'Montserrat', 'RaleWay', 'Ubuntu', Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}


html, body, main {
    background-color: $background-primary;
    height: 100%;
    width: 100vw;
}

body {
    overflow-x: hidden;
}

h2 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 3rem;
}

@media (max-width: 400px) {
    h2 {
        font-size: 2.5rem;
    }
}

.spinner-div {
    margin: 20vh auto;
    width: 150px;
}
