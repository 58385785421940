.divider__container {
  background-color: #253450;
  color: #fff;
  padding: 6rem 0;
  text-align: center;
  width: 100%;

  p {
    font-size: 1.5rem;
    font-weight: 500;
    font-style: oblique;
    margin: auto;
    width: 90%;
  }
}
